@import '../../../../assets/stylesheets/variables';

.CartNavigationContent {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  margin: 30px 0 15px;
  margin-bottom: 25px;

  @media (min-width: $screen-sm-min) {
    width: 40px;
    margin: 0 15px 0 0;
  }
}

.CartItemsCount {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  line-height: 5px;
  border-radius: 50%;
  text-align: center;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    line-height: 0;
    color: $lumepic-black-color;
    font-size: 10px;
    font-weight: bold;
  }
}

.CartIcon {
  width: 25px;
  height: 25px;
}

.CartContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.CartSummary {
  width: 100%;
  background-color: $lumepic-light-grey-color;
  border-top: 1px solid #cccccc;
  padding: 10px;

  h2 {
    font-size: 30px;
    align-self: center;
    margin-bottom: 10px;
  }
}

.CartLines {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  padding: 10px;
}

.CartLine {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.MiniCartView {
  width: 320px;
  height: auto;
  position: absolute;
  background-color: $custom-white;
  border: 1px solid #cccccc;
  right: 20px;
  top: 55px;
  z-index: 500;
}

.CartInfo {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  p {
    color: $lumepic-black-color;
  }
}

.noItemsView {
  color: $lumepic-dark-grey-color;
}
