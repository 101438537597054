.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  cursor: default;
}
.Dark{
  background-color: rgba(0, 0, 0, 0.5);
}

.Transparent {
  background-color: transparent;
}
