@import '../../assets/stylesheets/variables';

.Content {
  overflow-y: hidden;
  background-color: rgb(248, 248, 248);
  height: 100%;
  background-position: bottom;
  background-repeat: unset;
  background-size: 100%;

  @media (min-width: 768px) {
    min-height: calc(100vh - 302px); // Restamos navbar y footer
  }

  > div:last-child {
    margin-top: 0px;
  }

  div,
  span {
    font-family: 'Euclid Circular A';
  }
}
