/* Custom Web Colors */
$lumepic-black-color: rgb(51, 51, 51);
$primary-color: rgb(206, 182, 123);
$secondary-color: #e5dcb3;
$lumepic-light-gold-color: #ddc895;
$lumepic-white-color: rgb(255, 255, 255);
$lumepic-light-grey-color: rgb(248, 248, 248);
$lumepic-grey-color: rgb(99, 99, 99);
$lumepic-medium-grey-color: rgb(225, 225, 225);
$lumepic-dark-grey-color: rgb(153, 153, 153);
$lumepic-border-color: #ebebeb;
$lumepic-succes-dark-green: #4bb276;
$lumepic-succes-light-green: #e2f3e9;
$lumepic-error-dark-red: #cc0000;
