@import '../../assets/stylesheets/variables';

.FullAlbumContainer {
  .AlbumInformation {
    margin: 20px 0;

    @media (min-width: $screen-sm-min) {
      margin: 40px 0;
    }

    h4 {
      font-weight: $font-light;
    }

    p {
      margin: 0;

      &.AlbumInformationLocation {
        color: $lumepic-grey-color;
      }
    }
  }

  .detailIcons {
    color: $lumepic-grey-color;
    font-size: 1rem;
    height: auto;
    width: 1.1rem;
  }

  .ImageCaption {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    button {
      margin: 0;
      padding: 0;
      height: auto;
      text-align: right;

      p {
        text-decoration: underline;
      }
    }
  }
}
div,
span {
  font-family: 'Euclid Circular A';
}
