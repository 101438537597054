@import '../node_modules/react-toastify/dist/ReactToastify.css';
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  /*margin: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif !important;
}
/* body::-webkit-scrollbar {
  display: none !important;
} */
/* ::-webkit-scrollbar {
  display: none;
} */

/*code {*/
/*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*  monospace;*/
/*}*/

html,
body,
#root,
.App {
  height: 100vh;
}
