//Stylesheets Import
@import 'colors';
@import 'fonts';
@import 'forms';
@import 'mediaqueries';

//Font Awesome Import
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');

/* --- OLD STYLES - To be removed as we replace them with new variables --- */

/* COLORS */
$custom-dark: #001b4a; //deep blue
$custom-black: #000000;
$custom-gray: #adaaaa;
$custom-sky-blue: #007ab3; //lavender-- #947ff7;
$custom-sky-blue-transparent: hsla(199, 100%, 35%, 0.473);
$custom-gray-2: #3a3c42;
$custom-gray-3: #d8d7d7;
$dart-gray: #757575;
$dart-gray-light: #a8a8a8;
$custom-white: #ffffff; //cards
$custom-white-2: #f7f7f9; //background-white
$custom-white-3: #ededef; //shadow for customBlack
$custom-green: #e0ebc8;
$selector: #727272;
$separator: #dedede;

$logo-blue: #5dbcd2;
//RGB code:	rgb(93, 188, 210)
$material-blue: #1976d2;
$background-1: $primary-color;
$background-image: $primary-color;
$background-2: $secondary-color;
$success-color: #28a745;
$warning-color: #ffc107;
$error-color: #f11d01;
$danger-color: $error-color;
$button-hover: $secondary-color;
$shadow: #000000d9;

/* General purpose variables */

$navbar-height: 60px;
$toolbar-mobile-height: 56px;
$toolbar-desktop-height: 70px;
$drawer-width: 20vw;

/* z-index variables */

$drawer-container-z-index: 2;
$drawer-overlay-z-index: 1;
$drawer-menu-z-index: 2;
$navbar-container-z-index: 3;
$secondary-navbar-container-z-index: 1;

/* Breakpoint-related variables and classes */

$extra-large-min-width: 1500px;

$large-max-width: 1499px;
$large-min-width: 1200px;

$mediumMaxWidth: 1199px;
$mediumMinWidth: 992px;

$smallMaxWidth: 991px;
$smallMinWidth: 768px;

$extraSmallMaxWidth: 767px;
$extraSmallMinWidth: 576px;

$mobileThreshold: 575px;
$screen-mobile-max: ($mobileThreshold - 1) !default;
$screen-desktop-min: ($mobileThreshold) !default;

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: $dart-gray;
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;

  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.MainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100% - 70px);
  padding: 30px;
  //overflow-y: hidden;
  position: fixed;
  margin: 10px;
  overflow-y: scroll;
}

@media (max-width: $extraSmallMaxWidth) and (min-width: $extraSmallMinWidth) {
  .mainContainer {
    width: $extraSmallMinWidth;
  }
}

@media (max-width: $screen-desktop-min) {
  .MainContainer {
    flex-direction: column;
    max-width: 100%;
    padding: 10px;
    margin: 0px;
  }
}

.carouselNextIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
  background-image: url('../icons/v2/right-arrow.svg');
}

.carouselPrevIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
  background-image: url('../icons/v2/left-arrow.svg');
}

.MobileOnly {
  @media (min-width: $screen-desktop-min) {
    display: none !important;
  }
}
.DesktopOnly {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  @media (max-width: $screen-mobile-max) {
    display: none !important;
  }
}
