.preloaderLogo {
  padding-bottom: 3rem;
  animation: fade-in 0.4s ease-in-out;
}

@keyframes fade-in {
  0% {
    scale: 0.2;
    opacity: 0.8;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}
