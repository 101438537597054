@import '../../../assets/stylesheets/variables';

.Open {
  @media (max-width: $screen-mobile-max) {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }
}

.Close {
  @media (max-width: $screen-mobile-max) {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    display: none;
  }
}

@keyframes fade {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.searchBarContainerHeader {
  margin-top: 30px;

  @media (min-width: $screen-md-min) {
    margin-bottom: 30px;
  }
}

//Spot, date from and to facets
.FacetsContainer {
  flex-direction: column;
  align-items: center;
}

//Facets container
.FiltersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  flex-direction: column;

  @media (min-width: $screen-sm-min) {
    flex-direction: row;
    border: 1px solid $lumepic-border-color;
    padding: 0 20px 10px;
  }

  label {
    color: $lumepic-black-color;
    font-weight: $font-medium;
  }
}

//Selector Icons
.iconViewSelector {
  width: 15px;
}

//List, grid or map selectors
.ViewSelectorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;

  @media (min-width: $screen-sm-min) {
    justify-content: flex-end;
    margin: 0;
  }
}

//Selectors boxes
.navButton {
  background: transparent;
  border: 1px solid $lumepic-border-color;
  border-radius: 10px;
  transition: all 0.2s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:nth-of-type(2) {
    margin: 0 10px;
  }

  &:hover {
    background-color: $lumepic-light-grey-color;
  }

  &.ActiveView {
    background-color: $lumepic-black-color;

    &:hover {
      background-color: $lumepic-black-color;
    }
  }
}

//Clear filters
.searchBarContainerClear {
  margin-bottom: 12px;
  text-align: center;
  margin-left: 2px;

  @media (min-width: $screen-sm-min) {
    text-align: left;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 0;

    span {
      font-size: 12px;
      margin-right: 5px;
    }

    img {
      max-width: 10px;
    }
  }
}
