//Text Import
@font-face {
  font-family: 'Euclid Circular A';
  src: url('../font/EuclidCircularA-Light.eot');
  src: local('Euclid Circular A Light'), local('EuclidCircularA-Light'),
    url('../font/EuclidCircularA-Light.eot?#iefix') format('embedded-opentype'),
    url('../font/EuclidCircularA-Light.woff2') format('woff2'),
    url('../font/EuclidCircularA-Light.woff') format('woff'),
    url('../font/EuclidCircularA-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../font/EuclidCircularA-Regular.eot');
  src: local('Euclid Circular A Regular'), local('EuclidCircularA-Regular'),
    url('../font/EuclidCircularA-Regular.eot?#iefix') format('embedded-opentype'),
    url('../font/EuclidCircularA-Regular.woff2') format('woff2'),
    url('../font/EuclidCircularA-Regular.woff') format('woff'),
    url('../font/EuclidCircularA-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../font/EuclidCircularA-Medium.eot');
  src: local('Euclid Circular A Medium'), local('EuclidCircularA-Medium'),
    url('../font/EuclidCircularA-Medium.eot?#iefix') format('embedded-opentype'),
    url('../font/EuclidCircularA-Medium.woff2') format('woff2'),
    url('../font/EuclidCircularA-Medium.woff') format('woff'),
    url('../font/EuclidCircularA-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../font/EuclidCircularA-SemiBold.eot');
  src: local('Euclid Circular A SemiBold'), local('EuclidCircularA-SemiBold'),
    url('../font/EuclidCircularA-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../font/EuclidCircularA-SemiBold.woff2') format('woff2'),
    url('../font/EuclidCircularA-SemiBold.woff') format('woff'),
    url('../font/EuclidCircularA-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Euclid Circular A';
  src: url('../font/EuclidCircularA-Bold.eot');
  src: local('Euclid Circular A Bold'), local('EuclidCircularA-Bold'),
    url('../font/EuclidCircularA-Bold.eot?#iefix') format('embedded-opentype'),
    url('../font/EuclidCircularA-Bold.woff2') format('woff2'),
    url('../font/EuclidCircularA-Bold.woff') format('woff'),
    url('../font/EuclidCircularA-Bold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

//Font Variables
$font-family: 'Euclid Circular A';
$font-light: 200;
$font-regular: 300;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 800;

//Font Application
p {
  font-family: $font-family;
  font-weight: $font-regular;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family;
  font-weight: $font-regular;
}
