@import './assets/stylesheets/variables';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.bs-tooltip-left .arrow::before {
  background-color: transparent;
}

.ReactModal__Body--open,
.ReactModal__Html--open {
  overflow: hidden;
}
